<template>
	<div class="notauth">
		<p>
			Not Authorised<br>
			<small>Please reload the site!</small>
		</p>
	</div>
</template>

<script>

	export default {
		name: 'NotAuth',
		components: {},
	}

</script>

<style lang="scss" scoped>

	.notauth {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: $font-size-big;
		line-height: $line-height;

		p {
			font-style: italic;
			text-align: center;
		}

		small {
			font-size: $font-size-normal;
			color: $primary
		}
	}

</style>
