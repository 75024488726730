<template>
	<div class="listgrid__item" :class="[status ? '' : 'listgrid__item--inactive']">

		<div class="listgrid__item__icon">
			<component v-if="externalIcon" :is="externalIcon" :data="data" class="listgrid__item__icon__img"/>
			<img v-else-if="data.icon" :src="data.icon.url" class="listgrid__item__icon__img" :alt="data.icon.description">
		</div>

		<div class="listgrid__item__info">
			<div class="listgrid__item__title">{{data.title}}</div>
			<div v-if="subtitle" class="listgrid__item__subtitle">{{subtitle}}</div>
			<div v-if="data.openPoints > 0" class="listgrid__item__openpoints">+{{data.openPoints}}</div>

			<router-link v-if="link && data.quizUrl" class="listgrid__item__link" :to="data.quizUrl">
				<i class="icon-arrow-right" />
			</router-link>
		</div>
	</div>
</template>

<script>
	import ModuleBadge from "../../modules/Learn/Modules/ModuleBadge";

	export default {
		name: 'ListGridItem',
		components: {
			ModuleBadge
		},
		props: [
			'data',
			'status',
			'subtitle',
			'externalIcon',
			'link'
		]
	}
</script>

<style lang="scss">

	.listgrid__item {

		$root: &;
		@mixin inactiv { @at-root #{$root}--inactive & { @content }}

		text-align: center;
		font-size: $font-size-small;
		line-height: $line-height;

		> * { margin: auto; }

		&--inactive > * { opacity: .4; }

		&__icon {
			$size: rem-calc(63);
			width: $size;
			height: $size;

			background-color: $secondary;
			border-radius: 50%;
			padding: $quarter-gutter / 1.5;

			&__img {

				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;

				@include inactiv {
					filter: grayscale(.6);
				}
			}

		}

		&__info {
			margin-top: $gutter/2;
			> * { margin: $gutter/6 0; }
		}

		&__openpoints {
			color: $blue;
			font-weight: $font-weight-bold;
		}

		&__title {
			margin-top: $half-gutter;
			font-weight: $font-weight-bold;
		}

		&__subtitle {
			color: $primary-lighter;
		}

		&__link {
			margin: $half-gutter 0;
			display: block;
			color: $primary;
		}

		.modulebadge:not(.modulebadge--complete) .modulebadge__bg__color {
			fill: $white;
		}
	}

</style>
