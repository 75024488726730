<template>
	<div class="dialog">
		<header class="dialog__header">
			<span class="dialog__close" @click="close()"></span>
		</header>
		<div class="dialog__body" v-html="message"></div>
		<div class="dialog__footer">
			<div class="buttongroup">
				<button class="button button--transparent" @click="close()" v-html="l10n.button_no"></button>
				<button class="button" @click="confirm()" v-html="l10n.button_yes"></button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'Dialog',

		props: {
			message: String,
			callbackConfirm: Function
		},
		methods: {
			confirm(){
				this.$parent.close();
				this.callbackConfirm();
			},
			close() {
				this.$parent.close();
			}
		},
		computed: {
			...mapState ('global', ['l10n','showModal'])
		}
	}
</script>

<style lang="scss">

	.dialog {
		overflow: hidden;
		max-width: calc(100% - #{$gutter});
		min-width: rem-calc(220);
		background-color: $white;
		box-shadow: 0 0 rem-calc(10) 0 rgba(0,0,0,0.20);

		&__close {
			$size: rem-calc(15);

			position: absolute;
			right: $quarter-gutter;
			top: $quarter-gutter;
			width: $size;
			height: $size;
			opacity: .6;
			transition: opacity $speed;
			cursor: pointer;

			@include hover-or-active {
				opacity: 1;
			}

			&:before, &:after {
				position: absolute;
				left: $size/2;
				content: ' ';
				height: $size;
				width: 2px;
				background-color: $light;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}

		}

		&__header {
			position: relative;
			padding: $quarter-gutter 0;
		}

		&__body {
			padding: $gutter;
			text-align: center;
			line-height: 1.5;
			font-weight: $font-weight-light;
			font-size: $font-size-normal;
		}

		&__footer {
			display: flex;
			justify-content: center;

			padding: $half-gutter $gutter;

			background: $gray-lighter;
			color: $light;

			.buttongroup {
				display: flex;
				width: 100%;
				.button {
					flex: 1;
					box-shadow: none!important;
				}
			}
		}
	}

</style>
