<template>
	<div class="module">
		<div class="module__wrapper">
			<div class="module__intro">
				<h1 class="module__intro__title">
					<small>{{ this.l10n.component_module }}</small>
					{{ module.title }}
				</h1>
				<ModuleBadge :data="module" />
				<div class="module__intro__description" v-html="module.description"></div>
				<div class="module__intro__estimatedtime" v-html="module.estimatedTime"></div>
			</div>
		</div>

		<ListWithTitle :data="module.lessons" v-if = "isLoaded">
			<template #title>{{ module.listtitle }}</template>
			<template #list="{data}">
				<LessonItem :data="data" />
			</template>
		</ListWithTitle>

    <Loader v-else/>

	</div>
</template>

<script>
	import ModuleBadge from './ModuleBadge.vue'
	import ListWithTitle from '~/components/General/ListWithTitle.vue'
	import LessonItem from '../Lessons/LessonItem.vue'
	import {mapState, mapActions} from 'vuex'
	import Loader from '~/components/General/Loader'

	export default {
		name: 'Module',

		components: {
			ModuleBadge,
			ListWithTitle,
			LessonItem,
			Loader
		},

		props: ['moduleId'],

		computed: {
			...mapState ('learn', ['module']),
			...mapState ('global', ['l10n']),
			isLoaded: function () {
				return this.module.id;
			}
		},

		created () {
			this.getModule (this.moduleId)
		},

		methods: {
			...mapActions ('learn', ['getModule'])
		}
	}
</script>

<style lang="scss">

	.module {

		$root: &;

		@include desktop {
			display: flex;
			max-width: $full-width;
			margin-left: auto;
			margin-right: auto;

			> * { flex: 1 }
		}

		&__wrapper {
			@include in($tablet, $desktop) {
				background: transparent linear-gradient(to right, $white 0, $white 50%, transparent 50%, transparent 100%);
			}
		}

		&__intro {

			@include clear;
			@include h-gap;

			@include tablet {
				background-color: $white;
				box-sizing: border-box;
				padding: 0 $gutter $gutter 0;
			}

			@include in($tablet, $desktop) {
				margin-left: auto;
				margin-right: auto;
				margin-top: $half-gutter;
				width: $towel-header-width;
				transform: translateX(calc( (-#{$towel-width} + #{$towel-header-width}) / 2  ));
			}

			@include desktop {
				margin: $gutter $gutter*3 0 0;
				padding: $quarter-gutter $gutter $gutter*2;
			}

			.modulebadge {
				float: left;
			}

			&__title {
				@include is-h1;

				@include desktop {
					margin-bottom: 1.5em;
				}
			}

			&__description, &__estimatedtime {
				margin-left: rem-calc(80);
				font-size: $font-size-small;
				line-height: $line-height;

				@include desktop {
					font-size: $font-size-normal;
				}
			}
		}

	}

</style>

<style lang="scss" scoped>

	.list {
		margin-top: $gutter;
	}

</style>
