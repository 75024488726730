<template>
	<div class="info">
		<h1 class="info__title action__title">{{ action.title }}</h1>
		<div class="info__content" v-html="action.html"></div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'Info',
		computed: {
			...mapState ('learn', ['action'])
		},
		mounted () {
			this.$store.commit ('learn/actionAnswerStatus', 'idle')
		}
	}
</script>

<style lang="scss">

	.info {

		flex: 1;

		&__title {}
		&__content {
			@include bar-bottom-margin;
		}
	}

</style>
