<template>
	<router-link class="backlink button button--arrow icon-arrow-long-left" :to="href" />
</template>

<script>
	export default {
		name: 'BackLink',
		data () {
			return {
				href: ''
			}
		},
		watch: {
			'$route' () {
				this.update();
			}
		},
		mounted () {
			this.update();
		},
		methods: {
			update () {
				this.href = this.$route.path.split('/').slice(0,-1).join('/');
			}
		}
	}
</script>

<style lang="scss">

	.backlink {

		@include h-gap;

		align-self: flex-start;
		margin-top: $half-gutter;
		font-size: rem-calc(10.76);
	}

</style>
