<template>
	<div class="learn" :class="getClass()">
		<BackLink v-if="backlinkVisibility()" />
		<router-view class="learn__content" />
	</div>
</template>

<script>

	import BreadCrumb from '~/components/General/BreadCrumb';
	import BackLink from "~/components/General/BackLink";

	export default {
		name: 'Learn',

		components: {
			BackLink,
			BreadCrumb
		},

		methods: {
			backlinkVisibility: function() {
				return this.$route.name !== 'Action' &&
					this.$route.name !== 'LessonResult' &&
					this.$route.name !== 'Quiz'
					;
			},

			getClass(){
				return this.$route.name ? 'learn--' + this.$route.name.toLowerCase() : '';
			}
		},

	}
</script>

<style scoped lang="scss">
	.learn {

		$root: &;
		position: relative;

		&--quiz { background-color: $white; }
		&--module {
			background-color: $white;
			@include tablet {
				background-color: transparent;
			}
		}
		&--action {
			overflow-y: scroll;
			//margin-bottom: $navbar-height + $progress-height;
		}

		&__content {}
	}


</style>

