<template>
	<div class="lessonbar quizbar" :class="lessonbarVisibiltyClass">

		<LessonProgress isVisible="true" />

		<div class="quizbar__menu">
			<button
				class="button button--link button--icon icon-close"
				@click="$store.commit('global/toggleModal', true)"
			></button>
			<span class="lessonbar__title">{{lesson.title}}</span>

			<button
				class="button button--icon icon-caret"
				:class="{ disabled: actionAnswerStatus === 'unanswered' }"
				@click="evaluateQuestion"
			></button>
		</div>

	</div>
</template>

<script>
	import Loader from '~/components/General/Loader';
	import LessonProgress from '~/modules/Learn/Lessons/LessonProgress';
	import {mapState, mapActions} from 'vuex';

	export default {
		name: 'LessonBar',
		components: {
			Loader,
			LessonProgress
		},
		props: {
			lesson: {}
		},
		data: function() {
			return {
				lessonbarVisibiltyClass: ''
			}
		},
		computed: {
			...mapState ('learn', ['action', 'actionAnswer', 'actionAnswerStatus'])
		},
		mounted: function() {
			setTimeout(() => { this.lessonbarVisibiltyClass = 'lessonbar--visible'; })
		},
		methods: {
			...mapActions ('learn', ['getActionResultForAnswer']),
			evaluateQuestion () {
				this.$store.commit ('learn/actionAnswerStatus', 'waiting');
				// check
				this.getActionResultForAnswer ({
					id: this.action.id,
					answerId: this.actionAnswer
				})
			}
		}
	}
</script>
<style lang="scss" scoped>

	.lessonbar {

		transform: translate3d(0,100%,0);
		transition: transform $speed $speed ease;

		&--visible {
			transform: translate3d(0,0,0);
		}

		&__title {
			color: $primary-lighter;
			font-size: $font-size-normal;
		}

		.icon-close { font-size: rem-calc(10) }

	}

	.disabled {
		cursor: not-allowed;
		pointer-events: none;
		opacity: 0.3;
	}

</style>
