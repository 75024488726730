<template>
	<div class="result result--lesson">
		<div class="result__wrapper">
			<div class="result__title">{{data.title}}</div>
			<div class="result__description">{{data.description}}</div>

			<svg width="100%" height="100%" viewBox="0 0 50 50" class="donut">

				<defs>
					<filter id="blur" width="4" height="4" x="-2" y="-2">
						<feGaussianBlur in="SourceGraphic" stdDeviation="4" />
					</filter>
					<filter id="dropshadow">
						<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 .2 0"/>
						<feGaussianBlur stdDeviation="1" />
						<feBlend mode="multiply" />
					</filter>
				</defs>

				<g class="donut__shadows" v-for="(status, index) in data.summary" :key="index">
					<LessonGradient :status="status" :index="index" :data="data" :calc="stepCalculation" />
				</g>

				<circle class="donut__dropbackground" :stroke-dasharray="dropDashArray" :stroke-dashoffset="dropDashOffset" stroke-width="20" />

				<g class="donut__group">
					<circle class="donut__ring" />

					<!-- segments drop shadow -->
					<circle class="donut__s donut__s--drop" stroke="#000" :stroke-dasharray="dropShadowDashArray" stroke-dashoffset="25" />

					<!-- bubble -->
					<circle class="donut__s donut__s--start" :stroke="dotStartColor" stroke-dashoffset="25" />

					<!-- segments -->
					<g class="donut__s__wrapper" v-for="(status, index) in data.summary" :key="index">
						<LessonSegment ref="segments" :status="status" :index="index" :data="data" :calc="stepCalculation" />
					</g>

					<!-- bubble -->
					<circle class="donut__s donut__s--end" :style="dotEndCss" :stroke="dotEndColor" :stroke-dashoffset="dotEndDashOffset" />

				</g>

				<circle class="donut__hole" />

				<text class="donut__text" text-anchor="middle">
					<tspan x="25" y="25" dx="1" class="donut__text__percentage">
						{{number}}<tspan class="donut__text__percentage__unit" dy="-3.1">%</tspan>
					</tspan>
				</text>

				<text class="donut__text" text-anchor="middle">
					<tspan x="25" y="30" class="donut__text__rating" :class="donutTextRatingVisibilityClass">{{data.rating}}</tspan>
				</text>

			</svg>

			<div class="result__title result__lesson__headline">{{l10n.component_lesson}}</div>
			<div class="result__description result__lesson__title">{{data.lessonTitle}}</div>

		</div>

	</div>
</template>

<script>
	import TWEEN from '@tweenjs/tween.js';
	import {mapState} from "vuex";
	import LessonSegment from './LessonSegment.vue'
	import LessonGradient from './LessonGradient.vue'

	export default {
		name: 'Lesson',
		props: ['data'],
		components: {
			LessonSegment,
			LessonGradient
		},

		data: function () {
			return {
				number: 0,
				donutTextRatingVisibilityClass: '',
				segments: '',
				time: 2000,
				dropDashArray: '100 0',
				dropShadowDashArray: '0 100',
				dropDashOffset: 25,
				dotStartColor: '#fff',
				dotEndColor: '#fff',
				dotEndDashOffset: '25',
				dotEndCss: '',
				isAnimated: false
			}
		},

		computed: {
			...mapState ('learn', ['actionsStatus']),
			...mapState ('global', ['l10n']),
			stepCalculation: function () {
				return {
					timePerStep: this.getTimePerStep(),
					stepWith: this.data.summary.length ? this.data.percentage / this.data.summary.length : 0
				};
			},

		},

		methods: {

			getTimePerStep: function() {
				return this.data.summary.length ? this.time / (this.data.summary.length) : 0;
			},

			tween: function () {

				if(this.isAnimated) return;
				this.isAnimated = true;


				function animate () {
					if (TWEEN.update()) {
						requestAnimationFrame(animate)
					}
				}

				// trigger segments
				this.$refs.segments.forEach((item, index) => {
					if(typeof item.tween === 'function') item.tween();
				});

				new TWEEN

					.Tween({
						number: 0
					})

					.to({
						number: this.data.percentage
					}, this.time)

					.onUpdate(tween => {

						// number in center
						this.number = tween.number.toFixed(0);

						// End Dot
						this.dotEndDashOffset = 25 - tween.number;

						// Drop
						this.dropDashArray = (100 - tween.number) + ' ' + tween.number;
						this.dropDashOffset = 25 + 100 - tween.number;

						// Drop Shadow
						this.dropShadowDashArray = tween.number + ' ' + (100 - tween.number);

					})

					.onComplete(tween => {
						this.donutTextRatingVisibilityClass = 'donut__text__rating--visible';
					})

					.start()
				;

				animate();

			}
		},

		mounted() {
			// start color from dot
			this.dotStartColor = LessonSegment.methods.getColor( this.data.summary[0] );
			this.dotEndCss = 'transition-duration: ' + this.getTimePerStep() + 'ms';
		}
	}

</script>
<style lang="scss" scoped>

	.result {
		&__wrapper {
			@include bar-bottom-margin;
		}
	}

	.donut {

		@include donut-size;

		&__text {
			fill: #fff;
			font-kerning: normal;
			font-feature-settings: "tnum";

			&__percentage {
				font-weight: $font-weight-bold;
				font-size: 8px;

				&__unit {
					font-weight: $font-weight-normal;
					font-size: 4px;
				}
			}

			&__rating {
				font-size: 2.4px;
				opacity: 0;

				transition: opacity .5s linear;

				&--visible {
					opacity: 1;
				}
			}
		}

	}

</style>
