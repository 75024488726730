<template>
	<div class="theme">
		<transition name="fade">

			<ListWithTitle :data="theme.modules" v-if="isLoaded">
				<template #title>{{ theme.listtitle }}</template>
				<template #list="{data}"><ModuleItem :data="data" /></template>
			</ListWithTitle>

			<Loader v-else/>

		</transition>

	</div>
</template>

<script>
	import {mapState, mapActions} from 'vuex';
	import ListWithTitle from '~/components/General/ListWithTitle';
	import ModuleItem from '../Modules/ModuleItem';
	import Loader from '~/components/General/Loader';

	export default {
		name: 'Theme',
		components: {
			ListWithTitle,
			ModuleItem,
			Loader
		},
		data: function(){
			return {
				show: true
			}
		},
		props: ['themeId'],
		computed: {
      ...mapState('learn', ['theme']),
      isLoaded: function() {
				return this.theme.id;
			}
		},
		created () {
			this.getTheme(this.themeId);
		},
		methods: {
			...mapActions('learn', ['getTheme'])
		}
	}
</script>
