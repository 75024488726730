<template>
	<div class="lessonbadge">
		<div class="lessonbadge__icon" :class="getClass()"></div>
	</div>
</template>

<script>
	export default {
		name: 'LessonBadge',
		components: {},
		computed: {
			isComplete: function () { return [8,16].indexOf(this.data.status) >= 0 }
		},
		methods: {
			getClass(){
				let result = '';
				if(this.isComplete) {
					result = this.data.progress.includes('100%') ?
						'lessonbadge__icon--complete icon-check' :
						'lessonbadge__icon--incomplete icon-check'
					;
				}
				return result;
			}
		},
		props: ['data']
	}
</script>
<style lang="scss" scoped>
	.lessonbadge {

		$size: rem-calc(34);

		&__icon {

			width: $size;
			height: $size;
			color: $white;
			background-color: $primary-lighter;

			border-radius: 50%;
			box-sizing: border-box;

			display: inline-flex;
			justify-content: center;
			align-items: center;

			&--complete {
				background-color: $primary;
			}

			&--incomplete {
				background-color: $light;
			}
		}

	}
</style>
