<template>
	<div v-if="data.id" class="modulebadge" :class="[data.status===4 || data.status===8 ? 'modulebadge--complete' : '']">

		<svg class="modulebadge__bg" width="63" height="63" viewport="0 0 63 63" xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fill-rule="evenodd">
				<path class="modulebadge__bg__border" d="M31.5 0c4.068 0 7.372 3.695 10.909 5.162 3.667 1.52 8.602 1.3 11.365 4.065 2.763 2.762 2.544 7.698 4.065 11.365 1.467 3.537 5.16 6.84 5.16 10.908 0 4.067-3.693 7.372-5.16 10.909-1.521 3.667-1.302 8.602-4.065 11.365-2.763 2.763-7.698 2.543-11.365 4.064C38.872 59.305 35.568 63 31.499 63c-4.066 0-7.37-3.695-10.907-5.162-3.667-1.521-8.603-1.301-11.365-4.064-2.764-2.763-2.544-7.7-4.065-11.365C3.695 38.872 0 35.567 0 31.499c0-4.066 3.695-7.37 5.162-10.907 1.52-3.667 1.3-8.603 4.065-11.365 2.763-2.764 7.698-2.544 11.365-4.065C24.129 3.695 27.432 0 31.5 0zm-.001 6.57C17.732 6.57 6.571 17.733 6.571 31.5c0 13.769 11.161 24.93 24.928 24.93 13.769 0 24.93-11.161 24.93-24.93 0-13.768-11.161-24.93-24.93-24.93z"/>
				<path class="modulebadge__bg__color" d="M56.429 31.5c0 13.769-11.161 24.93-24.93 24.93-13.767 0-24.928-11.161-24.928-24.93 0-13.768 11.161-24.93 24.928-24.93 13.769 0 24.93 11.162 24.93 24.93"/>
				<circle class="modulebadge__bg__accent" cx="31.5" cy="31.5" r="25" stroke-width="1.5"></circle>
				<circle class="modulebadge__bg__progress" cx="31.5" cy="31.5" r="25" :stroke-dasharray="dashArray" stroke-linecap="round" stroke-dashoffset="0" stroke-width="3"></circle>
			</g>
		</svg>

		<img v-if="data.icon" :src="data.icon.url" class="modulebadge__icon" :alt="data.icon.description">
		<img v-else="data.icon" src="/assets/images/module-default-badge.svg" class="modulebadge__icon" alt="">

		<div class="modulebadge__progress" v-html="data.progress"></div>

	</div>
</template>

<script>

	export default {
		name: 'ModuleBadge',

		methods: {
			setDashArray() {
				this.dashArray = '0 156';
				if(this.data.percentage) setTimeout(() => {
					this.initial = false;
					this.dashArray = this.calculateDashArray(this.data.percentage);
				}, 200);
			},

			calculateDashArray(percentage) {
				percentage = percentage * 1.56;
				return percentage + ' ' + (156 - percentage);
			},
		},


		data: function() {
			return {
				initial: true,
				dashArray: '0 156'
			}
		},

		updated: function() {
			if(this.initial) this.setDashArray();
		},

		created: function() {
			this.setDashArray();
		},

		props: {
			data: {
				icon: null
			}
		}
	}
</script>
<style lang="scss">

	.modulebadge {

		$root: &;
		$size: rem-calc(63);
		$progressSize: rem-calc(20);

		@include is-noselect;

		position: relative;
		width: $size;
		height: $size;

		text-align: center;

		&__bg {

			width: $size;
			height: $size;

			&__border { fill: $primary-light }

			&__color {
				fill: transparent;
				transition: fill $speed-slow;
				@at-root #{$root}--complete & { fill: $primary; }
			}

			&__accent {
				stroke: $gray-lighter;
				transition: stroke $speed-slow;
				@at-root #{$root}--complete & { stroke: $white; }
			}

			&__progress {
				stroke: $primary;
				//stroke-dasharray: 0, 156;
				transform: rotate(-90deg);
				transform-origin: center;
				transition: stroke-dasharray $speed-slow, stroke $speed-slow;
				will-change: stroke-dasharray;

				@at-root #{$root}--complete & {
					stroke-dasharray: 156, 0!important;
					stroke: transparent!important;
				}
			}

		}

		&__icon {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			filter: invert(0);
			transition: filter $speed-slow;

			font-size: 0;

			width: 50%;
			height: 50%;
			object-fit: contain;

			@at-root #{$root}--complete & { filter: invert(1); }
		}

		&__progress {

			position: absolute;
			display: block;

			right: rem-calc(3);
			bottom: rem-calc(3);

			width: $progressSize;
			height: $progressSize;
			background-color: $primary-alternative;
			color: $primary-light;

			border-radius: 50%;

			text-align: center;
			font-size: $font-size-small;
			white-space: nowrap;
			line-height: $font-size-small*2;

			b {
				display: inline-block;
				color: $white;
				font-weight: $font-weight-bold;
			}
		}

	}
</style>
