<template>
	<div class="badgelevel" :class="[typeof this.profile.level.number === 'number' ? 'badgelevel--loaded' : '']">

		<div :class="dashArray"></div>

		<svg width="30px" height="30px" viewBox="0 0 30 30" class="donut">
			<circle class="badgelevel__ring" cx="15" cy="15" r="14" fill="transparent" stroke-width=".5" stroke="#fff" />
			<circle class="badgelevel__segment" cx="15" cy="15" r="14" fill="transparent" stroke-width="2" stroke="#fff" :stroke-dasharray="dashArray" stroke-dashoffset="0" />
			<text v-if="typeof this.profile.level.number === 'number'" y="70%" class="badgelevel__number" x="50%" text-anchor="middle">{{ profile.level.number }}</text>
		</svg>
		<div class="badgelevel__text">Level</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'

	export default {

		name: 'BadgeLevel',

		data () {
			return {
				dashArray : '0 87'
			};
		},

		watch: {
			profile: function (val) {
				if(val.nextLevelPercentage >=0) this.dashArray = this.calculateDashArray(val.nextLevelPercentage);
			},
		},

		computed: {
			...mapState ('client', ['profile']),
		},

		methods: {
			calculateDashArray: (percentage) => {
				percentage = percentage * .87;
				return percentage + ' ' + (87 - percentage);
			},
		},
	}
</script>

<style lang="scss" scoped>


	.badgelevel {

		$root: &;
		@include is-noselect;

		opacity: 0;
		transition: opacity $speed;
		will-change: opacity;

		&--loaded {
			opacity: 1;
		}

		svg {
			display: block;
			margin: 0 auto;
		}

		&__ring {
			stroke: $primary-light;
		}

		&__segment {
			transform: rotate(-90deg);
			transform-origin: center;
			stroke-linecap: round;
			transition: stroke-dasharray $speed-slow;
			will-change: stroke-dasharray;
		}

		&__number {
			fill: $primary-light;
			font-weight: bold;
		}

		&__text {
			display: block;
			margin-top: $quarter-gutter/2;

			text-align: center;
			text-transform: uppercase;

			font-size: $font-size-smaller;
			letter-spacing: rem-calc(1.2);
			line-height: 1;
			color: $primary-light;

		}

	}

</style>
