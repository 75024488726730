<template>
	<div class="result result--badge" :class="resultVisibilityClass">
		<div class="result__wrapper">
			<div class="result__title">{{data.title}}</div>
			<div class="result__description">{{data.description}}</div>

			<div class="result--badge__icon">
				<img class="result--badge__icon__img" v-if="data.badge.icon" :src="data.badge.icon.url" :alt="data.badge.title" />
			</div>

			<div class="result__description result--badge__title">{{data.badge.title}}</div>
			<div class="result__title result--badge__subtitle">{{data.badge.subtitle}}</div>
			<div class="result--badge__points">
				<div v-if="data.badge.points > 0" class="result__description result--badge__points__level">{{addPoints}}</div>
				<div v-if="data.badge.openPoints > 0" class="result__description result--badge__points__open">{{addOpenPoints}}</div>
			</div>
		</div>
	</div>
</template>

<script>

	import {mapState} from "vuex";

	export default {
		name: 'Badge',
		props: {
			data: null
		},

		data: () => ({
			resultVisibilityClass: ''
		}),

		computed: {
			...mapState ('global', ['l10n']),
			addPoints: (component) => component.l10n.component_result_add_points.replace('{0}', component.data.badge.points),
			addOpenPoints: (component) => component.l10n.component_result_add_openpoints.replace('{0}', component.data.badge.openPoints)
		},

		methods: {
			tween: function () {
				this.resultVisibilityClass = 'result--visible';
			}
		},
	}
</script>
<style lang="scss" scoped>
	.result {

		$root: &;

		&--badge {

			@mixin visible { @at-root #{$root}--visible & { @content }}

			&__title, &__subtitle, &__points {
				opacity: 0;
				transition: opacity $speed-fast linear;
				@include visible { opacity: 1; }
			}

			&__title, &__subtitle {
				margin-bottom: $quarter-gutter;
			}

			&__title {
				transition-delay: $speed-fast;
			}

			&__subtitle {
				transition-delay: $speed-fast*2;
				&:empty { display: block }
			}

			&__icon {
				background-color: darken($secondary, 5%);
				border-radius: 50%;
				padding: $quarter-gutter;

				max-width: rem-calc(150);
				margin: $gutter*2 auto;

				transform: scale(.5);
				opacity: 0;
				transition: transform $speed cubic-bezier(0.31, 0.59, 0.32, 1.52), box-shadow $speed-slow $speed-fast/2, opacity $speed-fast linear;

				box-shadow: 0 0 50px 0 rgba(lighten($blue, 30%), .4);

				@include visible {
					transform: scale(1);
					opacity: 1;
					box-shadow: 0 0 50px 0 rgba(lighten($blue, 30%), 0);
				}

				&__img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

			}

			&__points {

				margin-top: $gutter;
				transition-delay: $speed-fast*3;

				&__level {
					color: $primary-alternative;
				}

				&__open {
					color: $blue;
				}

			}

		}
	}
</style>
