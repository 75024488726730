<template>
	<div v-if="action" class="action">
		<transition name="fade">
			<Modal v-if="showModal">
				<Dialog
					:message="cancelLessonMessage"
					:callbackConfirm="quitLesson"
				/>
			</Modal>
		</transition>

		<component :is="action.subtype" />

		<Loader v-if="actionAnswerStatus === 'waiting'" />

		<LessonBar :lesson="lesson" />

		<transition name="toastupdown">
			<Toast v-show="showToast" :message="toastMessage" :is-error="toastError" />
		</transition>

	</div>

	<div v-else><Loader /></div>
</template>

<script>
	import {mapState, mapActions, mapGetters} from 'vuex'
	import Info from './Info';
	import Radio from './Radio';
	import LessonBar from '../Lessons/LessonBar';
	import Toast from '~/components/General/Toast'
	import Loader from '~/components/General/Loader';

	import Modal from "~/components/General/Modal";
	import Dialog from "~/components/General/Dialog";

	export default {
		name: 'Action',

		components: {
			Info,
			Radio,
			LessonBar,
			Toast,
			Loader,
			Modal,
			Dialog
		},
		props: ['lessonId'],

		computed: {
			...mapState ('learn', ['lesson','actions', 'action', 'actionsStatus', 'actionResultForAnswer', 'actionAnswerStatus']),
			...mapState ('global', ['l10n','showModal']),
			cancelLessonMessage: function(){
				return this.l10n.modal_lesson_cancel.replace('{0}', '<br><b>' + this.lesson.title + '</b><br>');
			}
		},

		watch: {

			'action': function (action) {

				// reset answer
				if(action.subtype !== 'info') {
					this.$store.commit('learn/actionAnswerStatus', 'unanswered');
					this.$store.commit('learn/setActionAnswer', null);
				}

			},

			'actionResultForAnswer': function (answer) {
				this.answer = answer;


				if (this.answer.type === 'action') {

					const msg = (answer.hasOwnProperty('messages') && answer.messages.length) ? answer.messages[0].message : null;

					/**
					 * WRONG
					 */
					if (this.action.id === answer.id) {
						this.showToastError(true, msg, null);
						this.$store.commit('learn/actionAnswerStatus', 'idle');

						this.updateActionsStatus(false);
					}

					/**
					 * CORRECT
					 */
					else {
						this.$store.commit('learn/actionAnswerStatus', 'correct');

						this.updateActionsStatus(true);

						// show loader -> just make the action null and show the loader when null
						this.showToastError (false, msg, () => {
							// goto next
							this.$store.commit ('learn/actionAnswerStatus', 'idle');
							this.$store.commit ('learn/setAction', answer);
						})
					}
				}

				/**
				 * RESULT
				 */
				else if (this.answer.type === 'merged') {
					this.$store.commit ('learn/setLessonResult', answer);

					this.updateActionsStatus(true);

					this.$router.push({name: 'LessonResult'});
				}
			}
		},

		data () {
			return {
				answer: null,
				showToast: false,
				toastMessage: '',
				toastError: false,
                currentActionNum: 0
			}
		},

		created () {
			this.getLesson(this.lessonId);
		},

		mounted () {
		},

		methods: {
			...mapActions ('learn', ['getLesson', 'getAction']),

			showToastError (toastType, msg, callback) {

				if(msg !== null){

					this.showToast = true;
					this.toastError = toastType;
					this.toastMessage = msg;

					// hide toast
					setTimeout (() => { this.showToast = false; }, 3000);
				}

				// callback
				if (callback !== null) callback();

			},

			quitLesson: function(){
				this.$store.commit('learn/currentActionNum', 0);
				this.$router.push(
					this.$route.path.split('/').slice(0,-1).join('/')
				);
			},

			updateActionsStatus(correct){
				if(correct){
					this.currentActionNum++;
					this.$store.commit('learn/currentActionNum', this.currentActionNum);
				} else {
					if(this.actionsStatus[this.currentActionNum].numTries < 3){
						this.actionsStatus[this.currentActionNum].numTries++;
					}
				}
				this.$store.commit('learn/actionsStatus', this.actionsStatus);
			}
		}
	}
</script>
<style lang="scss">
	.action {

		display: flex;
		flex-direction: column;
		background-color: $white;
		min-height: 100%;

		@include frame-desktop;

		&__title {
			@include is-h1;
			@include h-gap;
		}
	}
</style>
