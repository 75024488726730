<template>
	<span
		class="counter counter--radar"
		:class="[this.counter > 0 ? 'counter--visible' : '']"
	>
		{{ counter }}
	</span>
</template>

<script>

	export default {
		name: 'Counter',

		props: {
			counter: {
				type: Number,
				default: 0
			}
		},

	}
</script>

<style lang="scss">

	.counter {

		$root: &;
		$size: 1rem;

		position: absolute;
		z-index: 10;

		display: inline-flex;
		justify-content: center;
		align-items: center;

		height: $size;
		min-width: $size;
		border-radius: $size;
		padding: 0 .2rem;
		box-sizing: border-box;

		background-color: $warning;
		color: $white;

		pointer-events: none;

		font-size: $font-size-smaller;
		font-style: normal;
		line-height: 1;

		transform: scale(0);
		transition: transform $speed cubic-bezier(0.175, 0.885, 0.32, 1.275);

		&--visible {
			transform: scale(1);
		}

		&--radar {

			backface-visibility: hidden;

			&:after {
				font-size: 0;
				content: '';

				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				display: inline-block;
				height: calc(#{$size} - 2px);
				width: calc(100% - 2px);
				border-radius: 50%;
				border: 1px solid $warning;

				transform-origin: center center;
				animation: radar 5s infinite;
			}
		}

	}

	@keyframes radar {
		0% { transform: scale(1); opacity: 1; }
		100% { transform: scale(1.7); opacity: 0; }
	}

</style>
