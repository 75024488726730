<template>
	<transition name="fade">
		<div class="progress" :class="[progressClass, loadedClass()]">
			<div
				v-for="(val, index) in actionsStatus"
				:key="index"
				class="progress__step"
				:class="stepClass(val, index)"
			></div>
		</div>
	</transition>
</template>

<script>
	import {mapState} from 'vuex';

	export default {

		name: 'LessonProgress',

		props: {
			isResult: false,
			isVisible: false,
		},

		data: () => ({
			progressClass: ''
		}),

		computed: {
			...mapState ('learn', ['actionsStatus','currentActionNum']),
		},

		mounted() {
			setTimeout( () => {
				this.progressClass = this.isResult ? 'progress--result' : ''; },
			1);
		},

		methods: {

			loadedClass (){
				let classes = [];
				if(this.actionsStatus.length && this.isVisible) classes.push('progress--visible');
				return classes.join(' ');
			},

			stepClass (item, index) {

				let classes = ['progress__step--' + item.subtype];

				if(this.currentActionNum === index) classes.push('progress__step--current');

				if(item.numTries === 0 && index < this.currentActionNum){
					classes.push('progress__step--perfect');
				}

				// limit val
				let numTries = item.numTries;
				if(typeof numTries > 3){
					numTries = 3;
				}

				classes.push('progress__step--' + numTries);

				return classes.join(' ');
			}
		}
	}
</script>

<style lang="scss">

	.progress {
		$root: &;
		$height: $progress-height;

		width: 100%;
		bottom: 100%;
		position: absolute;
		display: flex;
		background-color: $secondary-alternative;

		min-height: 0;
		transition: min-height $speed-slow;

		&--visible { min-height: $height }

		@at-root &--result {
			min-height: $height * 3;
		}

		&:not(#{$root}--visible) {
			min-height: 0;
			max-height: 0;
		}

		&__step {

			flex: 1;
			background-color: lighten($secondary-light, 20%);
			transition: none $speed-slow;
			transition-property: flex-grow, margin-left, background-color;
			transform-origin: center;

			&:not(:first-child) {
				margin-left: 2px;
			}

			&--current {
				background-color: $light;
			}

			&--info {
				@at-root #{$root}--result & {
					// hide in result
					flex: 0;
					margin-left: 0;
				}
			}
			&--perfect, &--inforead {
				background-color: #FFE596;
			}
			&--1 {
				background-color: #E8C38D;
			}
			&--2 {
				background-color: #D6A66F;
			}
			&--3 {
				background-color: #BE7369;
			}
		}
	}



</style>
