<template>
	<div class="fatal">
		<Modal v-if="showModal && fatal.error">
			<Dialog :message="message" />
		</Modal>
	</div>
</template>

<script>

	import {mapState, mapActions} from "vuex";
	import Modal from "~/components/General/Modal";
	import Dialog from "~/components/General/Dialog";

	export default {
		name: 'Fatal',

		data: function() {
			return {
				message: ''
			}
		},

		components: {
			Modal,
			Dialog
		},

		computed: {
			...mapState ('global', ['showModal', 'fatal'])
		},

		watch: {
			fatal: function (value) {
				if(value.status && (value.status < 200 || value.status >= 400)) {
					this.message = value.error;
					this.$store.commit('global/toggleModal', true);
				} else {
					this.$store.commit('global/toggleModal', false);
					this.message = '';
				}
			}
		},
	}
</script>

<style lang="scss">
	.fatal {
		.modal { z-index: 99999;}

		.dialog {
			&__footer, &__header { display: none; }
			&__body {
				white-space: pre;
			}
		}
	}
</style>
