<template>
	<div class="not-found">Not found</div>
</template>

<script>

	export default {
		name: 'NotFound',
		components: {},
	}

</script>
