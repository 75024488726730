<template>
	<span
		class="toast"
		:class="{ 'toast--error': isError }"
		v-html="message"
	>
	</span>
</template>

<script>

	export default {
		name: 'Toast',
		components: {},
		props: ['message', 'isError']
	}
</script>

<style lang="scss" scoped>

	.toast {

		@include is-noselect;

		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: $navbar-height + $big-gutter;
		padding: $gutter/1.5;
		min-width: rem-calc(220);

		background-color: $primary;
		border-radius: 2rem;

		font-size: $font-size-normal;
		text-align: center;
		line-height: $line-height;
		color: $white;

		&--error {
			background-color: $warning;
		}

	}
</style>
