<template>
	<div class="results__item">

		<div class="result result__level">
			<div class="result__wrapper">
				<div class="result__title">{{l10n.component_result_level}}</div>

				<svg width="100%" height="100%" viewBox="0 0 50 50" class="donut">

					<defs>
						<filter id="blur" width="4" height="4" x="-2" y="-2">
							<feGaussianBlur in="SourceGraphic" stdDeviation="4" />
						</filter>
						<filter id="dropshadow">
							<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
							<feOffset dx="0" dy="0" />
							<feGaussianBlur in="offOut" stdDeviation=".7" />
							<feBlend mode="multiply" />
						</filter>

						<linearGradient id="strokegradient" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stop-color="#00bc9b" />
							<stop offset="100%" stop-color="#5eaefd" />
						</linearGradient>

					</defs>

					<g class="donut__shadows">
						<circle class="donut__s donut__s--shadow"
							stroke="url(#strokegradient)"
							:stroke-dasharray="levelDashArrray"
							:stroke-dashoffset="levelDashOffset"
						/>
					</g>

					<circle class="donut__dropbackground" :stroke-dasharray="dropDashArray" :stroke-dashoffset="dropDashOffset" stroke-width="20" />

					<g class="donut__group">
						<circle class="donut__ring" />

						<!-- segments drop shadow -->
						<circle class="donut__s donut__s--drop" stroke="#000"
							:stroke-dasharray="levelDashArrray"
							:stroke-dashoffset="levelDashOffset" />

						<!-- segments -->
						<g class="donut__s__wrapper">
							<circle class="donut__s"
								stroke="url(#strokegradient)"
								:stroke-dasharray="levelDashArrray"
								:stroke-dashoffset="levelDashOffset"
								stroke-linecap="round"
							/>
						</g>

					</g>

					<circle class="donut__hole" />

					<text class="donut__text" text-anchor="middle" :class="levelNumberClass">
						<tspan x="25" y="25" class="donut__text__levelnumber__prev">{{levelNumber}}</tspan>
					</text>
					<text class="donut__text" text-anchor="middle" :class="levelNumberNextClass">
						<tspan x="25" y="25" class="donut__text__levelnumber__next">{{levelNextNumber}}</tspan>
					</text>
					<text class="donut__text" text-anchor="middle">
						<tspan x="25" y="30" class="donut__text__leveltext" >{{l10n.level}}</tspan>
					</text>

					<text text-anchor="middle" x="25" y="25" ref="pc" fill-opacity="0">
						<tspan class="pc">+{{newNextLevelPercentages.display.toFixed(1)}}%</tspan>
					</text>

				</svg>

				<ul class="result__list">

					<li class="result__list__item">
						<span class="result__list__item__label">{{l10n.component_result_level_lesson_completed}}</span>
						<span class="result__list__item__value">+{{getLevelPercentage(lessonResults.pointsEarned - lessonResults.pointsBonus)}}%</span>
					</li>

					<li v-if="parseInt(lessonResults.pointsBonus) > 0" class="result__list__item">
						<span class="result__list__item__label">{{l10n.component_result_level_lesson_bonus}}</span>
						<span class="result__list__item__value">+{{getLevelPercentage(lessonResults.pointsBonus)}}%</span>
					</li>

					<li v-if="badgesPoints > 0" class="result__list__item">
						<span class="result__list__item__label">{{l10n.component_result_level_lesson_badges}}</span>
						<span class="result__list__item__value">+{{getLevelPercentage(badgesPoints)}}%</span>
					</li>

				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import TWEEN from '@tweenjs/tween.js';
	import {mapState, mapActions} from "vuex";

	export default {
		name: 'level',
		props: {
			data: null
		},

		data: function() {
			return {
				levelDashArrray: '0 100',
				levelDashOffset: 25, // fixed
				dropDashArray: '100 0',
				dropDashOffset: 25,
				levelNumberClass: '',
				levelNumberNextClass: 'hidden',
				time: 1000,
				delay: 180,
				isAnimated: false,

				levelNumberNext: 0,
				newNextLevelPercentages: { display: 0, level: 0 },
				levelUpsCount: 0
			}
		},

		computed: {
			...mapState ('global', ['l10n']),
			...mapState ('client', ['profile']),

			levelNumber: function() { return this.profile.level.number; },

			nextLevelPercentage: function() { return this.profile.nextLevelPercentage; },
			nextLevelPoints: function() { return this.profile.nextLevelPoints; },

			lessonResults: function(){
				return this.data.results.reduce((item) => { return item.type === 'lesson' ? item : false})
			},

			badgesPoints: function(){

				let points = 0;
				const badgesResults = this.data.results.filter((item) => { return item.type === 'badge'});

				if(badgesResults.length){
					for(let result of badgesResults){
						points += result.pointsEarned;
					}
				}
				return parseInt(points);
			},
		},

		methods: {

			...mapActions ('client', ['getProfile']),

			/**
			 * Calculate Percentages
			 * Please call this.levelUpsCount before
			 */
			calculatePercentages: function() {
				let percentages = {
					display: 0,
					level: 0
				};

				if(this.levelUpsCount) {
					percentages.display = (100 - this.nextLevelPercentage) + ((this.levelUpsCount - 1) * 100) + this.lastLevelUpResult.nextLevelPercentage;
					percentages.level = this.lastLevelUpResult.nextLevelPercentage;
				} else {
					const progressPoints = this.profile.points - this.profile.level.points;
					const nextLevelRangePoints = this.nextLevelPoints - this.profile.level.points;
					percentages.level = (progressPoints + this.data.pointsEarned) / nextLevelRangePoints * 100;
					percentages.display = percentages.level - this.nextLevelPercentage;
				}

				return percentages;
			},

			/**
			 * Get last level up result or null
			 */
			getLastLevelUpResult(){
				const levelUpResults = this.getLevelUpResults();
				if(levelUpResults.length) return levelUpResults[levelUpResults.length - 1];
				return null;
			},

			/**
			 * Filter all level up results
			 */
			getLevelUpResults: function() {
				return this.data.results.filter(item => item.type === 'levelup');
			},

			/**
			 * Level Ups Count
			 */
			getLevelUpsCount: function() {
				let levelUpsTotal = 0;
				const levelUpResults = this.getLevelUpResults();
				levelUpResults.forEach((i) => { levelUpsTotal += i.levelUps; });
				return levelUpsTotal;
			},

			/**
			 * @param points
			 * @returns {number}
			 */
			getLevelPercentage: function(points) {
				return this.data.pointsEarned > 0
					? (this.newNextLevelPercentages.display / this.data.pointsEarned * points).toFixed(1)
					: 0.0
					;
			},

			/**
			 * @param pc
			 * @param grow
			 */
			setSegment: function(pc, grow = true) {
				this.levelDashArrray = pc + ' ' + (100 - pc);
				this.dropDashArray = (100 - pc) + ' ' + pc;
				if(grow) this.dropDashOffset = 25 + 100 - pc;
			},

			/**
			 * Set Segment Percentage Text
			 */
			setSegmentPercentage(pc, opacity = undefined) {
				let r = 10.5;
				let pi2 = 2*Math.PI;
				let pcPi = pi2 * (pc - 25) / 100;
				if(this.$refs.pc) {
					this.$refs.pc.setAttribute('dy', Math.sin(pcPi) * r);
					this.$refs.pc.setAttribute('dx', Math.cos(pcPi) * r);
					if(typeof opacity != "undefined") this.$refs.pc.setAttribute('fill-opacity', opacity);
				}
			},

			/**
			 * Tween
			 */
			tween: function () {

				if(this.isAnimated) return;
				this.isAnimated = true;

				function animate () {
					if (TWEEN.update()) {
						requestAnimationFrame(animate)
					}
				}

				const grow = new TWEEN
					.Tween({ number: this.nextLevelPercentage, opacity: -2 })
					.to({ number: 100, opacity: 1.0 }, this.time)
					.onUpdate(tween => {
						this.setSegment(tween.number);
						this.setSegmentPercentage(tween.number, tween.opacity);
					})
					.easing(TWEEN.Easing.Quadratic.InOut)
					.onComplete(tween => {
						this.levelNumberClass = 'blow';
						this.levelNumberNextClass = 'show';
					})
				;

				const revert = new TWEEN
					.Tween({ number: 100 })
					.to({ number: 0 }, this.time*.5)
					.delay(this.delay)
					.onUpdate(tween => {
						this.levelDashArrray = tween.number + ' ' + (100 - tween.number);
						this.levelDashOffset = (tween.number + 25);
						this.dropDashArray = (100 - tween.number) + ' ' + tween.number;
						this.dropDashOffset = 25;
					})
					.easing(TWEEN.Easing.Quadratic.Out)
				;

				const growFinaly = new TWEEN
					.Tween(
						{
							number: this.levelUpsCount ? 0 : this.nextLevelPercentage,
							opacity: this.levelUpsCount ? 1 : -2
						}
					)
					.to(
						{
							number: this.newNextLevelPercentages.level,
							opacity: 1.0
						},
						this.time
					)
					.delay(this.levelUpsCount ? 0 : this.delay)
					.onUpdate(tween => {
						this.setSegment(tween.number);
						this.setSegmentPercentage(tween.number, tween.opacity);
					})
					.onComplete(tween => {
						// update profile
						this.getProfile();
					})
					.easing(TWEEN.Easing.Quadratic.InOut)
				;

				grow.chain(revert);
				revert.chain(growFinaly);

				if(this.levelUpsCount) { grow.start(); }
				else { growFinaly.start(); }

				animate();

			}
		},

		created: function() {

			// last result
			this.lastLevelUpResult = this.getLastLevelUpResult();

			// next levelnumber
			this.levelNextNumber = (this.lastLevelUpResult) ? this.lastLevelUpResult.level.number : 0;

			// Count LevelUps
			this.levelUpsCount = this.getLevelUpsCount();

			// Progress
			this.newNextLevelPercentages = this.calculatePercentages();

			// svg
			let nextLevelPercentage = this.nextLevelPercentage;
			this.levelDashArrray = nextLevelPercentage + ' ' + (100 - nextLevelPercentage);
			this.dropDashArray = (100 - nextLevelPercentage) + ' ' + nextLevelPercentage;
			this.dropDashOffset = 25 + 100 - nextLevelPercentage;
		}
	}
</script>
<style lang="scss" scoped>

	.pc {
		font-size: 2px;
		fill: $primary-alternative;
		font-weight: $font-weight-bold;
	}

	.result {

		&__wrapper {
			@include bar-bottom-margin;
		}

		&__list {
			margin: 0 auto;
			max-width: 400px;
			@include is-list-title;

			&__item {
				display: flex;
				justify-content: space-between;
				padding-bottom: $quarter-gutter;

				&__label {
					text-align: left;
					color: $primary-lighter;
					padding-right: $gutter;
				}

				&__value {
					color: $primary-alternative;
					font-weight: $font-weight-bold;
				}
			}
		}

	}

	.donut {

		@include donut-size;

		&__text {

			transition: none 2s ease;
			transition-property: transform, opacity;
			transform-origin: center 35%;

			transform: scale(1);

			opacity: 1;

			&.hidden {
				transform: scale(.8);
				opacity: 0;
			}

			&.blow {
				transform: scale(2);
				opacity: 0;
			}

			&__levelnumber__prev, &__levelnumber__next {
				font-weight: $font-weight-bold;
				font-size: 10px;
			}

			&__leveltext {
				font-size: 2.4px;
			}
		}

	}

</style>
