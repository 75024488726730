// import router from '../router'
import baseService from '../../services/baseService'

const initialState = {
	profile: {
		title: '',
		name: '',
		level: {}
	},
	isAuth: false,
};

const mutations = {
	name: (state, name) => {
		state.name = name
	},
	setIsAuth: (state, isAuth) => {
		state.isAuth = isAuth
	},
	profile: (state, profile) => {
		state.profile = profile
	}
};

const getters = {
	isAuth: (state) => {
		return state.isAuth
	}
};

const actions = {
	auth: ({commit}) => {
	},
	getProfile: ({commit}) => {
		baseService
			.getProfile ()
			.then (res => {
				commit ('profile', res);
			})
			.catch (err => {
				console.error (err)
			})
	}
};

export default {
	namespaced: true,
	state: initialState,
	mutations,
	getters,
	actions
}
