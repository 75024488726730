<template>
	<router-link :to="{ name: 'Action', params: { lessonId: data.id } }" class="list__item" :class="getClass()">
		<LessonBadge :data="data" />
		<div>
			<div class="list__item__title">{{ data.title }}</div>
			<div class="list__item__subtitle">
				<span v-html="data.progress">data.progress</span>
				<span class="list__item__label label label--warning" v-if="showLabel()">{{l10n.button_improve}}</span></div>
		</div>
		<div>
			<span class="button button--icon" :class="[isComplete ? 'icon-reload button--hollow' : 'icon-caret button--secondary']" />
		</div>
	</router-link>
</template>

<script>
	import LessonBadge from './LessonBadge';
	import {mapState} from 'Vuex';

	export default {
		name: 'LessonItem',
		components: {
			LessonBadge
		},
		computed: {
			...mapState ('global', ['l10n']),
			isComplete: function () { return [8,16].indexOf(this.data.status) >= 0 }
		},
		methods: {
			showLabel(){
				return this.isComplete && !this.data.progress.includes('100%');
			},
			getClass(){
				switch (this.data.status) {
					/* TODO change icon */
					case 1:
					case 2:
						return 'list__item--new';
				}
				return '';
			}
		},
		props: ['data']
	}
</script>
<style lang="scss" scoped>
	.list__item {

		height: rem-calc(70);
		background-color: $primary-lighter;

		border-left: 3px solid transparent;

		&--new {
			background-color: $white;
			border-left-color: $primary;
			box-sizing: content-box;
		}

		&__label {
			display: inline-flex;
			margin: .1rem 0 0 $half-gutter;
		}

		> * {

			&:nth-child(1),
			&:nth-child(3) {
				flex: 0 0;
				margin: auto $half-gutter;
			}

			&:nth-child(2) {
				flex: 1 1 100%;
				min-width: 0;
			}
		}
	}
</style>
