<template>
	<router-link :to="{name: 'Theme', params: { themeId: data.id }}" class="list__item list__item--theme">
		<ThemeBadge :data="data" />
		<div class="list__item__content">
			<div class="list__item__type">{{ l10n.component_theme }}</div>
			<div class="list__item__title">{{ data.title }}</div>
			<div class="list__item__subtitle">{{ data.subtitle }}</div>
		</div>
		<div class="list__item__action">
			<span class="button button--arrow icon-arrow-right"></span>
			<span class="status">{{progress}}</span>
		</div>
		<div class="list__item__percentage" :style="percentageWidth"></div>
	</router-link>
</template>

<script>
	import ThemeBadge from './ThemeBadge'
	import {mapState} from 'vuex';

	export default {
		name: 'ThemeItem',
		components: {
			ThemeBadge
		},
		computed: {
			...mapState ('global', ['l10n']),
			progress: function () {
				return this.data && this.data.status
					? this.data.status.done + '/' + this.data.status.count
					: './.'
				;
			}
		},
		props: ['data'],
		data: function() {
			return {
				percentageWidth: ''
			}
		},
		created: function(){
			setTimeout(() => {
				this.percentageWidth = 'width:' + this.data.status.percentage + '%';
			}, 200);
		}
	}

</script>
<style lang="scss" scoped>

	.status {
		position: absolute;

		width: rem-calc(35);
		margin-top: rem-calc(-2);

		display: block;
		text-align: center;
		font-size: $font-size-small;
		white-space: normal;
		letter-spacing: .07em;
		color: $gray;

		font-variant: tabular-nums;
		font-feature-settings: "tnum";
	}

	.list__item {

		$size: rem-calc(100);
		height: $size;
		position: relative;
		background-color: $gray-lighter;
		box-shadow: none;
		border-bottom: 1px solid $primary-light;

		> *:first-child,
		&__action {
			flex: 0 0;
			margin: auto $half-gutter;
		}

		> *:first-child {
			margin-left: 0;
			flex-basis: $size;
		}

		&__content {
			flex: 1 1 100%;
			min-width: 0;
		}

		&__action {
			margin-right: $half-gutter;
		}

		&__subtitle {
			@include is-hyphens;
			white-space: normal;
		}

		&__percentage {
			position: absolute;
			bottom: 0;
			height: rem-calc(3);
			background-color: $primary;
			width: 0;
			transition: width $speed-slow;
		}

		.button { font-size: rem-calc(12); }

	}
</style>
