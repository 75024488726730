<template>
	<router-link :to="{ name: 'Module', params: getParams() }" class="list__item">
		<ModuleBadge :data="data" />
		<div class="list__item__content">
			<div class="list__item__type">{{ l10n.component_module }}</div>
			<div class="list__item__title">{{ data.title }}</div>
			<div class="list__item__subtitle">{{ data.subtitle }}</div>
		</div>
		<div class="list__item__action">
			<span class="button button--secondary button--icon icon-caret"></span>
		</div>
	</router-link>
</template>

<script>
	import ModuleBadge from './ModuleBadge.vue'
	import {mapState} from 'vuex';

	export default {
		name: 'ModuleItem',
		components: {
			ModuleBadge
		},
		methods: {
			getParams: function(){
				let params = {};
				if(this.data.hasOwnProperty('parent') && this.data.parent !== null){
					params.themeId = this.data.parent.id;
				}
				params.moduleId = this.data.id;
				return params;
			}
		},
		computed: {
			...mapState ('global', ['l10n'])
		},
		props: ['data']
	}
</script>
<style lang="scss" scoped>
	.list__item {

		> *:first-child,
		&__action {
			flex: 0 0;
			margin: auto $half-gutter;
		}

		&__content {
			flex: 1 1 100%;
			min-width: 0;
		}

	}
</style>
