const initialState = {
	l10n: [],
	showModal: false,
	fatal: {}
};

const mutations = {
	setL10n(state, l10n){
		state.l10n = l10n;
	},
	setFatal(state, fatal){
		state.fatal = fatal;
	},
	toggleModal(state, showOrHide){
		state.showModal = showOrHide;
	}
};

const getters = {};

const actions = {};

export default {
	namespaced: true,
	state: initialState,
	mutations,
	getters,
	actions
}
