<template>
	<ul class="breadcrumb">
		<li v-for="(b, val) in breadcrumb" :key="b" class="breadcrumb__item">
			<a :href="getLink(breadcrumb, val)">{{ b }}</a>
		</li>
	</ul>
</template>

<script>

	export default {
		name: 'BreadCrumb',
		components: {},
		data () {
			return {
				breadcrumb: []
			}
		},
		watch: {
			'$route' () {
				this.updateBreadcrumb ()
			}
		},
		mounted () {
			this.updateBreadcrumb ()
		},
		methods: {
			updateBreadcrumb () {
				this.breadcrumb = this.$route.meta.breadcrumb;
				this.breadcrumb = this.$route.path.split ('/').slice (2)
			},
			getLink (b, val) {
				return '/learn/' + b.slice (0, val + 1).join ('/')
			}
		}
	}
</script>

<style lang="scss">
	.breadcrumb {

	}
</style>
