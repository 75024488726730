<template>
	<circle class="donut__s"
		:style="css"
		:stroke="stroke"
		:stroke-dasharray="dashArray"
		:stroke-dashoffset="dashOffset"
	/>
</template>

<script>

	import TWEEN from '@tweenjs/tween.js';
	export default {
		name: 'LessonSegment',
		props: ['status', 'index', 'data', 'calc'],
		components: {},

		data: function() {
			return {
				dashArray: '0 100',
				dashOffset: '25',
				stroke: '#fff',
				css: '',
				isAnimated: false
			}
		},

		created: function() {
			this.stroke = this.getColor(  this.index === 0 ? this.status : this.data.summary[ this.index - 1 ]  );
		},

		methods: {

			getColor: function(s) {
				const colors = ['#FFE596','#E8C38D', '#D6A66F', '#BE7369'];
				return colors[s] ? colors[s] : '#fff';
			},

			tween: function () {

				if(this.isAnimated) return;
				this.isAnimated = true;

				function animate () {
					if (TWEEN.update()) {
						requestAnimationFrame(animate)
					}
				}

				new TWEEN
					.Tween({
						number: 0,
					})

					.to({
						number: this.calc.stepWith - .2
					}, this.calc.timePerStep)

					.delay(this.calc.timePerStep * this.index)

					.onUpdate(tween => {
						this.dashArray = tween.number + ' ' + (100 - tween.number);
					})

					.onStart(tween => {
						let nextColor = this.getColor(this.status);
						this.$parent.dotEndColor = nextColor;
						this.css = 'transition-duration: ' + this.calc.timePerStep + 'ms';
						this.dashOffset = 25 - (this.calc.stepWith * this.index);
						this.stroke = nextColor;
					})

					.start()
				;

				animate();

			}
		}
	}

</script>

<style lang="scss" scoped>

	circle {
		transition-property: stroke;
	}

</style>
