<template>
	<transition name="fadein">
		<div v-if="isLoaded" class="disclaimer">
			<div class="disclaimer__content">
				<span class="disclaimer__headline">{{ l10n.smoking_disclaimer_heets_headline }}</span>
				<span class="disclaimer__subline">{{ l10n.smoking_disclaimer_heets_subline }}</span>
			</div>
		</div>
	</transition>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'Disclaimer',
		computed: {
			...mapState('global', ['l10n']),
			isLoaded() {
				return this.l10n.smoking_disclaimer_heets_headline;
			}
		}
	}
</script>

<style lang="scss">

	.disclaimer {
		$root: &;

		display: flex;
		height: $navbar-height;
		box-sizing: border-box;
		position: relative;
		z-index: 1;

		border: 4px solid black;
		background-color: $white;
		color: black;

		&__content {
			margin: auto;
			max-width: 90%;
			text-align: center;
			line-height: 1.4;
			font-size: $font-size-small;

			& > * {
				display: inline-block;
			}

			@include tablet {
				max-width: rem-calc(636px);
				font-size: $font-size-normal;

				#{$root}__headline {
					font-weight: $font-weight-bold;
				}

			}
		}
	}
</style>
