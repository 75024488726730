<template>
	<div class="list">
		<h1 class="list__title"><slot name="title" /></h1>
		<div v-if="typeof data !== 'undefined' && data.length > 0">
			<slot v-for="d in data" name="list" :data="d" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ListWithTitle',
		props: ['data']
	}
</script>

<style lang="scss">

	.list {

		@include h-gap;

		$height: rem-calc(90);
		margin-top: $half-gutter;
		margin-bottom: $gutter;
		clear: both;

		@include tablet {
			max-width: $towel-width;
			margin-left: auto;
			margin-right: auto;
		}

		&__title {
			@include is-list-title;
		}

		> * {
			line-height: $line-height;
		}

		&__item {
			display: flex;
			align-items: center;
			margin: $half-gutter 0;
			height: $height;

			background-color: $white;
			box-shadow: $shadow-light;


			&__type, &__title, &__subtitle {
				@include is-textoverflow;
				font-size: $font-size-normal;
			}

			&__type {
				font-size: $font-size-small;
				text-transform: uppercase;
				color: $light;
				padding-bottom: $quarter-gutter/2;

				&:empty { display: none; }
			}

			&__title {
				font-weight: $font-weight-bold;
			}
		}

	}

</style>
