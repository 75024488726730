import Vue from 'vue'
import Vuex from 'vuex'

import globalStore from './global'
import clientStore from '~/modules/Client/store'
import learnStore from '~/modules/Learn/store'

Vue.use (Vuex);

export default new Vuex.Store ({
	modules: {
		client: clientStore,
		learn: learnStore,
		global: globalStore
	}
})
