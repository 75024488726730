<template>
	<div class="modal" v-if="showModal">
		<slot></slot>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'Modal',

		methods: {
			close() {
				this.$store.commit('global/toggleModal', false);
			}
		},
		computed: {
			...mapState ('global', ['showModal'])
		}
	}
</script>

<style lang="scss">

	.modal {
		position: fixed;
		z-index: 20;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($secondary, 0.5);
	}

</style>
