<template>
	<div id="app">
		<Fatal />
		<TopBar />
		<router-view class="container" />
		<NavigationBar additionalClass="navbar--bottom" />
		<footer>
			<Disclaimer v-if="l10n.nav_home" />
		</footer>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	import Fatal from '~/components/General/Fatal';
	import TopBar from '~/components/General/TopBar';
	import NavigationBar from '~/components/NavigationBar/NavigationBar'
	import Disclaimer from "./components/General/Disclaimer";

	export default {

		name: 'App',

		components: {
			Fatal,
			TopBar,
			NavigationBar,
			Disclaimer
		},

		methods: {
			...mapActions ('client', ['auth']),
		},

		computed: {
			...mapState ('global', ['l10n']),
			inIframe: function () {
				try { return window.self !== window.top; }
				catch (e) { return true; }
			}
		},

		created: function () {
			const setVh = () => {
				let pmiMenuHeight = 60;
				if(window.innerWidth >= 768) pmiMenuHeight = 45;
				pmiMenuHeight+=30; /* subnavigation */
				document.documentElement.style.setProperty('--vh', (window.innerHeight - pmiMenuHeight) * 0.01 + 'px');
			};

			if(this.inIframe) {
				let setVhTimeout = null;
				window.addEventListener('orientationchange', () => { clearTimeout(setVhTimeout); setVhTimeout = setTimeout(setVh, 250); });
				window.addEventListener('resize', () => { clearTimeout(setVhTimeout); setVhTimeout = setTimeout(setVh, 250); });
				setVh();
			}
		}

	}
</script>

<style lang="scss">

	@import '~/assets/styles/app.scss';

	body {
		font-family: "Noto Sans", Helvetica, Arial, sans-serif;
		font-weight: $font-weight-normal;
		line-height: 1;
		color: $black;

		overflow-x: hidden;

		//@include showBreakpoints;
	}

	#app {
		display: flex;
		flex-direction: column;
		height: calc(var(--vh, 1vh) * 100);
		transform: scale(1);
	}

	footer {
		@include tablet {
			margin-top: auto;
		}
	}

</style>
