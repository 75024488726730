<template>
	<div class="listgrid__wrapper">

		<h1 class="listgrid__title"><slot name="title" /></h1>

		<div class="listgrid" v-if="typeof data !== 'undefined' && data.length > 0">
			<slot v-for="d in data" name="list" :data="d" :status="status"></slot>
		</div>

		<slot name="footer"/>
	</div>
</template>

<script>
	export default {
		name: 'ListGrid',
		props: [
			'data',
			'status'
		]
	}
</script>

<style lang="scss">

	.listgrid {

		@include tablet {
			max-width: $full-width;
			margin-left: auto;
			margin-right: auto;
		}

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: $gutter;
		justify-items: center;

		@include desktop {
			grid-template-columns: repeat(4, 1fr);
		}

		&__title, &__footer {
			@include is-list-title;
		}

		&__footer {
			display: block;
			margin-top: $gutter;

			.icon-arrow-right {
				color: $primary;
				margin-left: $half-gutter;
			}
		}

	}
</style>
