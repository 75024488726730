<template>
	<div class="resultbar quizbar">

		<LessonProgress isResult="true" :isVisible="$parent.currentViewType === 'lesson'" />

		<div class="quizbar__menu">
			<div class="resultbar__link" v-if="lessonResult.quizBar.back">
				<router-link :to="lessonResult.quizBar.back.url">
					<button class="button button--icon icon-up button--hollow button--secondary" />
				</router-link>
				<span class="resultbar__link__label">{{lessonResult.quizBar.back.title}}</span>
			</div>

			<div class="resultbar__link" v-if="lessonResult.quizBar.next">
				<span class="resultbar__link__label">{{lessonResult.quizBar.next.title}}</span>
				<router-link :to="lessonResult.quizBar.next.url">
					<button class="button button--icon icon-caret" />
				</router-link>
			</div>
		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import LessonProgress from '~/modules/Learn/Lessons/LessonProgress';

	export default {

		name: 'ResultBar',

		components: {
			LessonProgress
		},

		props: {
			'lessonResult': null
		}
	}
</script>
<style lang="scss" scoped>

	.resultbar {
		
		@include desktop {
			margin-left: 50%;
			transform: translateX(-50%);
		}

		&__link {
			font-size: $font-size-small;
			line-height: $line-height;
			display: flex;
			align-items: center;

			&__label {
				padding: $gutter;
			}
		}
	}

	.icon-up {
		border-color: $white;
	}
</style>
