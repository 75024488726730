<template>
	<circle class="donut__s donut__s--shadow"
		:stroke="stroke"
		:stroke-dasharray="dashArray"
		:stroke-dashoffset="dashOffset"
	/>
</template>

<script>

	export default {
		name: 'LessonGradient',
		props: ['status', 'index', 'data', 'calc'],
		components: {},


		data: function() {
			return {
				dashArray: '0 100',
				dashOffset: '25',
				stroke: '#fff',
			}
		},

		created: function() {
			this.stroke = this.getColor(this.status );
			this.dashArray = this.calc.stepWith + ' ' + (100 - this.calc.stepWith);
			this.dashOffset = 25 - (this.calc.stepWith * this.index);
		},

		methods: {
			getColor: function(s) {
				const colors = ['#FFE596','#E8C38D', '#D6A66F', '#BE7369'];
				return colors[s] ? colors[s] : '#fff';
			},
		},

	}

</script>
