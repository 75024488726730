<template>
		<router-link :to="to" active-class="navbar__item--active" class="navbar__item">
			<i class="navbar__item__icon" :class="icon">
				<slot name="icon" />
				<Counter :counter="counter" />
			</i>
			<span class="navbar__item__label">&nbsp;{{ label }}&nbsp;</span>
		</router-link>
</template>

<script>

	import Counter from "../General/Counter";
	export default {
		name: 'NavigationBarButton',
		components: {Counter},
		props: {
			label: {
				type: String
			},
			image: {
				type: String
			},
			to: {
				type: String,
				default: '/home'
			},
			badge: {
				type: Boolean,
				default: false
			},
			icon: {
				type: String,
				default: ''
			},
			counter: {
				type: Number,
				default: 0
			}
		}
	}
</script>

<style lang="scss">

	.navbar__item {

		@mixin top { @at-root .navbar--top & { @content } }
		@mixin bottom { @at-root .navbar--bottom & { @content } }

		flex: 1;

		text-align: center;
		padding-top: .5rem;
		color: $light;
		white-space: nowrap;

		transition: background-color $speed;

		@include desktop {
			display: flex;
			align-items: center;
			padding-top: 0;

			margin-left: $big-gutter;
		}

		@include top {
			flex: 0 0 rem-calc(80)
		}

		@include hover-or-active {
			@include bottom { background-color: black; }
		}

		&--active {
			color: $primary;
			@include bottom { background-color: black; }
		}

		&__icon, &__label {
			display: block;
		}

		&__icon {
			&:before {
				width: auto!important;
				margin: 0!important;
				font-size: 1.4rem;
			}
		}

		&__label {
			margin-top: $quarter-gutter;
			@include is-smallprint;

			@include desktop {
				margin-left: $quarter-gutter;
				font-size: $font-size-normal;
				font-weight: $font-weight-light;

				-webkit-font-smoothing: inherit;
				-moz-osx-font-smoothing: inherit;
			}
		}

		.counter {
			margin-top: -.2rem;
			margin-left: -.7rem;
		}

	}

</style>
