<template>
	<div class="themebadge">
		<img v-if="data.icon" :src="data.icon.url" class="themebadge__icon" :alt="data.icon.description">
	</div>
</template>

<script>

	export default {
		name: 'ThemeBadge',
		components: {},
		props: ['data']
	}

</script>
<style lang="scss" scoped>
	.themebadge {

		$size: rem-calc(100);

		width: $size;
		height: $size;
		background-color: $white;

		&__icon {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
</style>
