import Learn from './Learn.vue'
import Quiz from './Quiz/Quiz.vue'
import Theme from './Themes/Theme.vue'
import Module from './Modules/Module.vue'
import Action from './Actions/Action.vue'
import LessonResult from './Lessons/LessonResult.vue'

import store from './store';

const routes = [
	{
		path: '/learn',
		name: 'Learn',
		component: Learn,
		children: [
			{
				path: 'themes',
				name: 'Quiz',
				component: Quiz,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'themes/:themeId',
				name: 'Theme',
				component: Theme,
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'themes/:themeId/:moduleId',
				name: 'Module',
				component: Module,
				props: true,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: 'themes/:themeId/:moduleId/:lessonId',
				name: 'Action',
				component: Action,
				props: true,
				meta: {
					requiresAuth: true,
					hideNavigationBar: true
				},
				beforeEnter(to, from, next) {

					if(from.name) {
						//store.commit('setLessonReturn', {path: from.fullPath, title: from.name});
					}

					next();
				}
			},
			{
				path: 'themes/:themeId/:moduleId/:lessonId/result',
				name: 'LessonResult',
				component: LessonResult,
				props: true,
				meta: {
					requiresAuth: true,
					hideNavigationBar: true
				},
				beforeEnter(to, from, next) {
					// check if lesson result
					// redirect to origin lesson

					if(!store.state.lessonResult) {
						next({
							name: 'Action',
							params: to.params
						})
					} else {
						next();
					}
				}
			}
		]
	}
];

export default routes
