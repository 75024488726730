<template>
	<div class="radio">
		<div class="radio__content">

			<h2 class="radio__title">{{ action.title }}</h2>

			<div class="answers">
				<div v-if="containImages" class="answersimage">
					<div class="answersimage__item"
						v-for="a in action.answers"
						:key="a.id"
						@click="selectAnswer(a.id)">
						<img v-if="a.image" :src="a.image.url" :class="{ selected: imageSelected === a.id }" />
					</div>
				</div>

				<div v-else class="answerstext">
					<label
						class="ui uiradio answerstext__item"
						:for="a.id"
						v-for="a in action.answers"
						:key="a.id"
					>
						<span class="uiradio__text">{{ a.title }}</span>
						<input
							:id="a.id"
							v-model="selected"
							type="radio"
							name="radio"
							:value="a.id"
						>
						<span class="uiradio__checkmark"></span>
					</label>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'

	export default {

		name: 'Radio',
		props: {
			msg: String,
		},

		data () {
			return {
				title: 'Title',
				subtitle: 'Subtitle',
				selected: null,
				imageSelected: -1
			}
		},

		watch: {
			'selected': function (val) {
				this.selectAnswer (val)
			},
		},

		computed: {
			...mapState ('learn', ['action', '']),
			containImages () {
				/** TODO: check the actionType */
				return !!(this.action.answers.length && this.action.answers[0].image);
			}
		},

		methods: {
			// ...mapActions('learn', ['getQuestion']),
			selectAnswer (id) {
				this.imageSelected = id;
				this.$store.commit ('learn/setActionAnswer', id);
				this.$store.commit ('learn/actionAnswerStatus', 'idle');
			}
		},

		mounted () {
			this.$store.commit ('learn/actionAnswerStatus', 'unanswered');
			this.$store.commit ('learn/setActionAnswer', null);
		}

	};
</script>

<style scoped lang="scss">

	.radio {

		flex: 1;
		@include is-scroll;

		&__title {
			font-size: $font-size-big;
			margin: $gutter*2 0;
			line-height: $line-height;

			@include desktop {
				@include is-h1;
				margin-top: $gutter*3;
			}
		}

		&__content {
			@include h-gap;
			@include bar-bottom-margin;

			@include desktop {
				max-width: rem-calc(415px);
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.answers {

		&text {

			&__item {
				display: flex;
			}

		}

		&image {

			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: $quarter-gutter;

			@include in($tablet, $desktop) {
				grid-template-columns: repeat(3, 1fr);
			}

			&__item {

				img {
					display: block;

					object-fit: cover;
					width: 100%;
					height: 100%;

					@include hover-or-active {
						outline: 3px solid $light;
					}

					&.selected {
						outline: 3px solid $primary;
					}
				}
			}

		}
	}

</style>
